import React, { useContext } from 'react';
import '../Login.css';
import logo from '../images/logo white-01 1.png';
import { AuthContext } from '../context/AuthContext'; // AuthContext import edilmesi
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../api/axiosClient';

function AddPassword() {
    const { login } = useContext(AuthContext); // login fonksiyonunu context'ten alıyoruz
    const navigate = useNavigate();
    const { token } = useParams()
    const handleLogin = async (e) => {
        e.preventDefault();
        axiosClient.post('/Auth/AddPasswordForNewUser', {
            "email": e.target.email.value,
            "token": token.replace(/@/g, '/'),
            "newPassword": e.target.repassword.value
        })
            .then(res => {
                navigate('/login');
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });
    };

    return (
        <div className='root'>
            <div className='login-left'>
                <img src={logo} alt='Logo' />
            </div>
            <div className='login-box'>
                <h2>Şifrə təyin edin!</h2>
                <form onSubmit={handleLogin}>
                    <div className='txtb'>
                        <label>Email</label>
                        <input type='text' name='email' placeholder='Email' required />
                    </div>
                    <div className='txtb'>
                        <label>Şifrə</label>
                        <input type='password' name='password' placeholder='Şifrə' required />
                    </div>
                    <div className='txtb'>
                        <label>Şifrənin təkrarı</label>
                        <input type='password' name='repassword' placeholder='Şifrənin təkrarı' required />
                    </div>
                    <button type='submit'>Yadda saxla</button>
                </form>
            </div>
        </div>
    );
}

export default AddPassword;
