import React, { useEffect, useState } from 'react'
import axiosClient from '../api/axiosClient';
import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
const MENU_ID = 'blahblah';
function Home() {
  const [modal, setModal] = useState(false)
  const [modalRemove, setModalRemove] = useState(false)
  const [modalUpdate, setModalUpdate] = useState(false)
  const [data, setData] = useState([])
  const [deleteUserId, setDeleteUserId] = useState(null)
  const [editedUser, setEditedUser] = useState({
    "id": null,
    "name": null,
    "surname": null,
    "email": null,
    "phoneNumber": null
  })
  const { show } = useContextMenu({
    id: MENU_ID,
  });
  useEffect(() => {
    axiosClient.get('/Auth/GetAllUsers')
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        console.error('Error fetching data:', err);
      });
  }, []);
  const addUser = (e) => {
    e.preventDefault();
    axiosClient.post('/Auth/Register', {
      "name": e.target.name.value,
      "surname": e.target.surname.value,
      "email": e.target.email.value,
      "phoneNumber": e.target.phoneNumber.value
    })
      .then(res => {
        setData([{ users: res.data }].concat(data));
        setModal(false)
      })
      .catch(err => {
        console.error('Error fetching data:', err);
      });
  }
  const updateUser = (e) => {
    e.preventDefault();
    axiosClient.put('/Auth/UpdateUserInformation/' + editedUser.id, {
      "name": e.target.name.value,
      "surname": e.target.surname.value,
      "email": e.target.email.value,
      "phoneNumber": e.target.phoneNumber.value
    })
      .then(res => {
        const _data = data;
        const _row = _data.find(x => x.users.id == editedUser.id).users;
        _row.name = e.target.name.value;
        _row.surname = e.target.surname.value;
        _row.email = e.target.email.value;
        _row.phoneNumber = e.target.phoneNumber.value;
        setData(_data)
        setModalUpdate(false)
      })
      .catch(err => {
        console.error('Error fetching data:', err);
      });
  }
  const deleteUser = () => {
    axiosClient.delete('/Auth/DeleteUser/' + deleteUserId).then(res => {
      setData(data.filter(d => d.users.id !== deleteUserId))
      setModalRemove(false)
    })
      .catch(err => {
        console.error('Error fetching data:', err);
      });
  }
  function handleContextMenu(event, itemid) {
    show({
      event,
      props: {
        itemid
      }
    })
  }
  const handleItemClick = ({ id, event, props }) => {
    switch (id) {
      case "edit":
        setModalUpdate(true);
        const u = data.find(x => x.users.id == props.itemid).users;
        u.id = props.itemid
        setEditedUser(u);
        break;
      case "remove":
        setDeleteUserId(props.itemid)
        setModalRemove(true)
        break;
    }
  }
  return (
    <>
      <div className='card' style={{ overflow: "auto" }}>
        <div className='card-header'>
          <h2 className='card-title'>
            İstifadəçilər
          </h2>
          <button className='card-button' onClick={() => setModal(true)}>Yeni istifadəçi</button>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th>№</th>
              <th>Ad</th>
              <th>Soyad</th>
              <th>Email</th>
              <th>Telefon</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((d, i) => {
                return <tr key={d.users.email} onContextMenu={(event) => handleContextMenu(event, d.users.id)}>
                  <td>{i + 1}</td>
                  <td>{d.users.name}</td>
                  <td>{d.users.surname}</td>
                  <td>{d.users.email}</td>
                  <td>{d.users.phoneNumber}</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
      {
        modal && (
          <div className='user-modal'>
            <form className='modal-card' onSubmit={addUser}>
              <div className='modal-header'>
                <h4 className='modal-title'>Yeni istifadəçi</h4>
                <i className="fa-solid fa-xmark" onClick={() => setModal(false)}></i>
              </div>
              <div className='modal-body'>
                <input className='form-control' required name="name" placeholder='Ad' />
                <input className='form-control' required name="surname" placeholder='Soyad' />
                <input className='form-control' required name="email" placeholder='Email' />
                <input className='form-control' required name="phoneNumber" placeholder='Telefon' />
              </div>
              <div className='modal-footer'>
                <button type='button' className='cancel' onClick={() => setModal(false)}>Ləğv et</button>
                <button type="submit" className='save'>Yadda saxla</button>
              </div>
            </form>
          </div>
        )
      }      {
        modalUpdate && (
          <div className='user-modal'>
            <form className='modal-card' onSubmit={updateUser}>
              <div className='modal-header'>
                <h4 className='modal-title'>İstifadəçi dəyiş</h4>
                <i className="fa-solid fa-xmark" onClick={() => setModalUpdate(false)}></i>
              </div>
              <div className='modal-body'>
                <input className='form-control' required name="name" placeholder='Ad' defaultValue={editedUser.name} />
                <input className='form-control' required name="surname" placeholder='Soyad' defaultValue={editedUser.surname} />
                <input className='form-control' required name="email" placeholder='Email' defaultValue={editedUser.email} />
                <input className='form-control' required name="phoneNumber" placeholder='Telefon' defaultValue={editedUser.phoneNumber} />
              </div>
              <div className='modal-footer'>
                <button type='button' className='cancel' onClick={() => setModalUpdate(false)}>Ləğv et</button>
                <button type="submit" className='save'>Yadda saxla</button>
              </div>
            </form>
          </div>
        )
      }
      {
        modalRemove && (
          <div className='user-modal modal-remove'>
            <div className='modal-card'>
              <div className='modal-header'>
                <h4 className='modal-title'>Sil</h4>
                <i className="fa-solid fa-xmark" onClick={() => setModalRemove(false)}></i>
              </div>
              <div className='modal-body'>
                <h3>İstifadəçini silmək istədiyinizə əminsinizmi?</h3>
              </div>
              <div className='modal-footer'>
                <button type='button' className='cancel' onClick={() => setModalRemove(false)}>Ləğv et</button>
                <button type="submit" className='save' onClick={deleteUser}>Bəli</button>
              </div>
            </div>
          </div>
        )
      }
      <Menu id={MENU_ID}>
        <Item id="edit" onClick={handleItemClick}>Dəyiş</Item>
        <Item id="remove" onClick={handleItemClick}>Sil</Item>
      </Menu>
    </>
  )
}

export default Home