import React, { useContext, useState } from 'react';
import pin from '../images/pin.svg';
import logo from '../images/logo.png';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // AuthContext'i import ediyoruz

function Nav() {
    const { auth, logout } = useContext(AuthContext);
    const [pinned, setPinned] = useState(false)
    const handleLogout = () => {
        logout();
    };

    return (
        <nav className={pinned ? "pin" : ""}>
            <div className='nav-header'>
                <Link to="//siesco.az">
                    <img src={logo} alt='Logo'/>
                </Link>
                <img src={pin} alt='Pin' onClick={() => setPinned(!pinned)} />
            </div>
            <ul>
                {auth.userStatus === "Admin" && <li>
                    <NavLink to="/users">
                        <i className='fa fa-user'></i>
                        <span>İstifadəçilər</span>
                    </NavLink>
                </li>}
                <li>
                    <NavLink to="/">
                        <i className='fa-brands fa-youtube'></i>
                        <span>Videolar</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/documents">
                        <i className='fa-solid fa-book'></i>
                        <span>Dərsliklər</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/login" onClick={handleLogout}>
                        <i className='fa-solid fa-user'></i>
                        <span>Çıxış</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}

export default Nav;
