import React, { createContext, useState, useEffect } from 'react';
import axiosClient from '../api/axiosClient';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    userFullName: localStorage.getItem('userFullName'),
    userStatus: localStorage.getItem('userStatus')
  });

  useEffect(() => {
    if (auth.accessToken) {
      axiosClient.defaults.headers.common['Authorization'] = `Bearer ${auth.accessToken}`;
    }
  }, [auth.accessToken]);

  const login = async (email, password) => {
    const response = await axiosClient.post('/auth/login', { email, password });
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    localStorage.setItem('userFullName', response.data.userFullName);
    localStorage.setItem('userStatus', response.data.userStatus);
    setAuth({
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      userFullName: response.data.userFullName,
      userStatus: response.data.userStatus,
    });
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userFullName');
    localStorage.removeItem('userStatus');
    setAuth({
      accessToken: null,
      refreshToken: null,
      userFullName: null,
      userStatus: null,
    });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
