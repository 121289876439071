import React, { useEffect, useState } from 'react'
import axiosClient from '../../api/axiosClient';
import { useNavigate, useParams } from 'react-router-dom';
import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { toast } from 'react-toastify';
const MENU_ID = 'blahblah';
function Videos() {
    const [data, setData] = useState(null);
    const navigate = useNavigate()
    const { id } = useParams();
    const [folderModal, setFolderModal] = useState(false)
    const [videoModal, setvideoModal] = useState(false)
    const [contextType, setContextType] = useState("folder")
    const [editModal, setEditModal] = useState(false)
    const [editedItem, setEditedItem] = useState()
    const { show } = useContextMenu({
        id: MENU_ID,
    });
    function handleContextMenu(event, type, itemid) {
        show({
            event,
            props: {
                type,
                itemid
            }
        })
        setContextType(type)
    }
    const handleItemClick = ({ id, event, props }) => {
        switch (id) {
            case "open":
                setData(null);
                navigate('/editvideos/' + props.itemid)
                break;
            case "remove":
                removeItem(props.type, props.itemid)
                break;
            case "edit":
                editItem(props.type, props.itemid)
                break;
            case "rules":
                navigate("/videorules/" + props.itemid);
                break;
            //etc...
        }
    }
    useEffect(() => {
        axiosClient.get('/Groups/get/' + (id !== undefined ? id : ""))
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });
    }, [id]);
    const addFolder = (e) => {
        e.preventDefault();
        axiosClient.post('/groups/create', {
            "name": e.target.name.value,
            "parentId": id,
        })
            .then(res => {
                let upd = {
                    ...data,
                    children: data.children.concat({ id: res.data, name: e.target.name.value })
                }
                setData(upd)
                setFolderModal(false)
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });
    }
    const addVideo = (e) => {
        e.preventDefault();
        axiosClient.post('/videos/create', {
            "title": e.target.title.value,
            "videoFile": e.target.videoFile.value,
            "groupId": id,
        })
            .then(res => {
                console.log(res.data)
                let upd = {
                    ...data,
                    videos: data.videos.concat({ id: res.data, title: e.target.title.value, videoFile: e.target.videoFile.value })
                }
                setData(upd)
                setvideoModal(false)
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });
    }
    const removeItem = (type, id) => {
        switch (type) {
            case 'folder':
                axiosClient.delete('/groups/delete/' + id).then(res => {
                    let upd = {
                        ...data,
                        children: data.children.filter(x => x.id != id)
                    }
                    setData(upd)
                }).catch(err => {
                    toast.dismiss();
                    toast.error("Qrupu silmək üçün içindəki bütün elementlər silinməlidir.")
                });
                break;
            case 'video':
                axiosClient.delete('/videos/delete/' + id).then(res => {
                    let upd = {
                        ...data,
                        videos: data.videos.filter(x => x.id != id)
                    }
                    setData(upd)
                }).catch(err => {
                    console.error('Error fetching data:', err);
                });
                break;
        }
    }
    const editItem = (type, id) => {
        setEditModal(true)
        setEditedItem({
            id: id,
            type: type,
            name: type === "video" ? data.videos.find(x => x.id === id).title : data.children.find(x => x.id === id).name,
            videoFile: type === "video" ? data.videos.find(x => x.id === id).videoFile : null
        })
        console.log(data.videos)
    }
    const updateMehtod = (e) => {
        e.preventDefault();
        if (editedItem.type === "video") {
            axiosClient.put('/videos/update/' + editedItem.id, {
                "title": e.target.name.value,
                "videoFile": e.target.videoFile.value,
            }).then(res => {
                const _data = {
                    ...data,
                    videos: data.videos.map(video =>
                        video.id === editedItem.id
                            ? { ...video, title: e.target.name.value, videoFile: e.target.videoFile.value }
                            : video
                    )
                };
                setData(_data);
                setEditModal(false)
            }).catch(err => {
                console.error('Error fetching data:', err);
            });
        } else if (editedItem.type === "folder") {
            console.log(data)
            axiosClient.put('/groups/update/' + editedItem.id, {
                "name": e.target.name.value,
            }).then(res => {
                const _data = {
                    ...data,
                    children: data.children.map(child =>
                        child.id === editedItem.id
                            ? { ...child, name: e.target.name.value }
                            : child
                    )
                };
                setData(_data);
                setEditModal(false)
            }).catch(err => {
                console.error('Error fetching data:', err);
            });
        }
    }
    return (
        <>
            <div className='admin-video'>
                <ul>
                    {
                        <li className={'card ' + (id === undefined ? "disable-back" : null)} onClick={() => {
                            if (id !== undefined) { setData(null); navigate(-1) }
                        }
                        }><i className="fa-solid fa-rotate-left"></i> Geri</li>
                    }
                    <li className='card' onClick={() => setFolderModal(true)}><i className="fa-solid fa-folder-plus"></i> Yeni qovluq</li>
                    <li className={'card ' + (id === undefined ? "disable-back" : null)} onClick={() => id !== undefined && setvideoModal(true)}><i className="fa-solid fa-file-circle-plus"></i> Yeni video</li>
                </ul>
                <ul className='explorer'>
                    {
                        data !== null ? <>
                            {data.children && data.children.map((g, i) => {
                                return <li nan="salam" key={i} onContextMenu={(event) => handleContextMenu(event, "folder", g.id)} className='card' onClick={() => { setData(null); navigate('/editvideos/' + g.id) }}><i className="fa-regular fa-folder"></i> {g.name}</li>
                            })}
                            {data.videos && data.videos.map((v, i) => {
                                return <li key={i} onContextMenu={(event) => handleContextMenu(event, "video", v.id)} className='card'><i className="fa-regular fa-file-video"></i> {v.title}</li>
                            })}
                        </> : null
                    }
                </ul>
            </div>
            {
                folderModal && <div className='user-modal modal-add-folder'>
                    <form className='modal-card' onSubmit={addFolder}>
                        <div className='modal-header'>
                            <h4 className='modal-title'>Yeni qovluq</h4>
                            <i className="fa-solid fa-xmark" onClick={() => setFolderModal(false)}></i>
                        </div>
                        <div className='modal-body'>
                            <input className='form-control' required name="name" placeholder='Ad' />
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='cancel' onClick={() => setFolderModal(false)}>Ləğv et</button>
                            <button type="submit" className='save'>Yadda saxla</button>
                        </div>
                    </form>
                </div>
            }
            {
                videoModal && <div className='user-modal modal-video-folder'>
                    <form className='modal-card' onSubmit={addVideo}>
                        <div className='modal-header'>
                            <h4 className='modal-title'>Yeni video</h4>
                            <i className="fa-solid fa-xmark" onClick={() => setvideoModal(false)}></i>
                        </div>
                        <div className='modal-body'>
                            <input className='form-control' required name="title" placeholder='Ad' />
                            <input className='form-control' required name="videoFile" placeholder='Link' />
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='cancel' onClick={() => setvideoModal(false)}>Ləğv et</button>
                            <button type="submit" className='save'>Yadda saxla</button>
                        </div>
                    </form>
                </div>
            }
            {
                editModal && <div className='user-modal modal-add-folder'>
                    <form className='modal-card' onSubmit={updateMehtod}>
                        <div className='modal-header'>
                            <h4 className='modal-title'> {editedItem.type === "video" ? "Videonu dəyiş" : "Qovluğu dəyiş"}</h4>
                            <i className="fa-solid fa-xmark" onClick={() => setEditModal(false)}></i>
                        </div>
                        <div className='modal-body'>
                            <input className='form-control' required name="name" placeholder='Ad' defaultValue={editedItem.name} />
                            {
                                editedItem.type === "video" && <input className='form-control' required name="videoFile" placeholder='Link' defaultValue={editedItem.videoFile} />
                            }

                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='cancel' onClick={() => setEditModal(false)}>Ləğv et</button>
                            <button type="submit" className='save'>Yadda saxla</button>
                        </div>
                    </form>
                </div>
            }
            <Menu id={MENU_ID}>
                {
                    contextType === "folder" && <Item id="open" onClick={handleItemClick}>Aç</Item>
                }
                <Item id="remove" onClick={handleItemClick}>Sil</Item>
                <Item id="edit" onClick={handleItemClick}>Dəyiş</Item>
                {
                    contextType === "folder" && <Item id="rules" onClick={handleItemClick}>Səlahiyyətlər</Item>
                }
            </Menu>
        </>
    )
}

export default Videos