import React, { useContext } from 'react'
import '../App.css'
import Nav from './Nav';
import { Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function Layout() {
    const { auth } = useContext(AuthContext);
    console.log(auth)
    return (
        <>
            <Nav />
            <main>
                <header>
                    <div className='search'>
                        <input type='search' placeholder='Axtar'/>
                        <i className='fa fa-search'></i>
                    </div>
                    <span>{auth.userFullName} <i className="fa-regular fa-user"></i></span>
                </header>
                <Outlet />
            </main>
        </>
    )
}

export default Layout