import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../api/axiosClient';

function DocumentRules() {
    const { id } = useParams();
    const navigate = useNavigate()
    const [data, setData] = useState([])
    useEffect(() => {
        axiosClient.get('/Permissions/GroupLessonUserPermissionList/' + id)
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });
    }, []);
    const giveRule = (userId) => {
        axiosClient.post('/Permissions/GiveGroupLessonPermission', {
            "userId": userId,
            "groupLessonId": id
        })
            .then(res => {
                const upd = data;
                data.find(d => d.users.id == userId).hasPermission = res.data
                setData(upd);
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });
    }
    return (
        <div className='card' style={{ overflow: "auto" }}>
            <h3 className='rules-header'> <i className='fa-solid fa-rotate-left' onClick={() => navigate(-1)}></i> Qovluq üzrə icazələr</h3>
            <table className='table'>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Ad</th>
                        <th>Soyad</th>
                        <th>Icazə</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((d, i) => {
                            return <tr key={d.users.id}>
                                <td>{i + 1}</td>
                                <td>{d.users.name}</td>
                                <td>{d.users.surname}</td>
                                <td><input type="checkbox" onChange={() => giveRule(d.users.id)} defaultChecked={d.hasPermission} /></td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default DocumentRules