import React, { useContext, useEffect, useState } from 'react';
import { Treebeard, decorators } from 'react-treebeard';
import { FaFileContract } from 'react-icons/fa';
import { MdFolder, MdFolderOpen } from 'react-icons/md';
import axiosClient from '../api/axiosClient';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import doc from '../images/document.svg'
import cover from '../images/siesco video cover 2-01.png'

const mapData = (data) => {
    return data.map(item => ({
        id: item.id,
        name: item.name,
        lessons: item.lessons,
        children: [
            ...mapData(item.children),
            ...item.lessons.map(lesson => ({
                id: lesson.id,
                name: lesson.title,
                lessonFile: lesson.lessonFile,
                type: 'lesson',
                description: lesson.description
            }))
        ]
    }));
};

function Documents() {
    const [cursor, setCursor] = useState(null);
    const [data, setData] = useState([]);
    const [curDocument, setCurDocument] = useState(null);
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();
    const customDecorators = {
        ...decorators,
        Header: ({ style, node }) => {
            const iconType = node.children ? (node.toggled ? <MdFolderOpen /> : <MdFolder />) : (node.type === 'lesson' ? <FaFileContract /> : null);
            return (
                <div style={style.base}>
                    <div style={style.title}>
                        {iconType} {node.name}
                    </div>
                </div>
            );
        }
    };

    const onToggle = (node, toggled) => {
        if (node.type === 'lesson') {
            setCurDocument(node);
        }
        if (cursor) {
            cursor.active = false;
        }
        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }
        setCursor(node);
        setData(Object.assign({}, data));
    };

    useEffect(() => {
        axiosClient.get('/GroupLessons/GetAllWithPermission')
            .then(res => {
                setData({
                    name: 'Bölmələr',
                    toggled: true,
                    children: mapData(res.data)
                });
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });
    }, []);
    [].reduce(() => {

    }, 50)
    return (
        <div className='video-container'>
            <div className='card card-document'>
                <h2>{curDocument && curDocument.name}</h2>
                {curDocument ? <div className='document-content'>
                    <p>
                        {curDocument.description}
                    </p>
                    <div className='document-file'>
                        <div className='document-file-img'>
                            <img src={doc} />
                        </div>
                        <a target='_blank' href={"https://eduapi.siesco.az/" + curDocument.lessonFile}>Yüklə</a>
                    </div>
                </div> : <img src={cover} style={{ width: '100%' }} />
                }
            </div>
            <div className='card card-lesson'>
                <div className='card-header'>
                    <h3 className='card-title'>Dərsliklər</h3>
                    {
                        auth.userStatus === "Admin" && <button className='card-button' onClick={() => navigate('/editdocuments')}>Ayarlar</button>
                    }

                </div>
                <ul className='lesson-category'>
                    <Treebeard data={data} onToggle={onToggle} decorators={customDecorators} />
                </ul>
            </div>
        </div>
    );
}

export default Documents;
