import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, BrowserRouter, HashRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Error from './pages/Error';
import Layout from './components/Layout';
import Videos from './pages/Videos';
import Login from './pages/Login';
import EditVideos from './pages/Admin/Videos'
import { AuthProvider, AuthContext } from './context/AuthContext';
import Documents from './pages/Documents';
import EditDocuments from './pages/Admin/Documents';
import VideoRules from './pages/Admin/VideoRules';
import DocumentRules from './pages/Admin/DocumentRules';
import AddPassword from './pages/AddPassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from './pages/ResetPassword';
const App = () => {
  return (
    <AuthProvider>
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/AddPassword/:token" element={<AddPassword />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
            <Route index element={<Videos />} />
            <Route path='documents' element={<Documents />} />
            <Route path='users' element={<AdminRoute> <Home /></AdminRoute>} />
            <Route path='editvideos/:id?' element={<AdminRoute> <EditVideos /></AdminRoute>} />
            <Route path='editdocuments/:id?' element={<AdminRoute> <EditDocuments /></AdminRoute>} />
            <Route path='videorules/:id?' element={<AdminRoute> <VideoRules /></AdminRoute>} />
            <Route path='documentrules/:id?' element={<AdminRoute> <DocumentRules /></AdminRoute>} />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
        <ToastContainer />
      </HashRouter>
    </AuthProvider>
  );
};

const PrivateRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);
  return auth.accessToken ? children : <Navigate to="/login" />;
};

const AdminRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);
  return auth.userStatus === 'Admin' ? children : <Navigate to="/" />;
};

export default App;
