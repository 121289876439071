import React, { useContext, useEffect, useState } from 'react';
import { Treebeard, decorators } from 'react-treebeard';
import { FaFileVideo } from 'react-icons/fa';
import { MdFolder, MdFolderOpen } from 'react-icons/md';
import axiosClient from '../api/axiosClient'; // axiosClient import ediliyor
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import cover from '../images/siesco video cover 2-01.png'
const mapData = (data) => {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    videos: item.videos,
    children: [
      ...mapData(item.children),
      ...item.videos.map(video => ({
        id: video.id,
        name: video.title,
        videoFile: video.videoFile,
        type: 'video'
      }))
    ]
  }));
};

function Videos() {
  const [cursor, setCursor] = useState(null);
  const [data, setData] = useState([]);
  const [curVideo, setCurVideo] = useState();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const customDecorators = {
    ...decorators,
    Header: ({ style, node }) => {
      const iconType = node.children ? (node.toggled ? <MdFolderOpen /> : <MdFolder />) : (node.type === 'video' ? <FaFileVideo /> : null);
      return (
        <div style={style.base}>
          <div style={style.title}>
            {iconType} {node.name}
          </div>
        </div>
      );
    }
  };

  const onToggle = (node, toggled) => {
    if (node.type === 'video') {
      setCurVideo(node.videoFile);
    }
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(node);
    setData(Object.assign({}, data));
  };

  useEffect(() => {
    axiosClient.get('/Groups/GetAllWithPermission')
      .then(res => {
        setData({
          name: 'Bölmələr',
          toggled: true,
          children: mapData(res.data)
        });
      })
      .catch(err => {
        console.error('Error fetching data:', err);
      });
  }, []);

  return (
    <div className='video-container'>
      <div className='card card-video'>
        {
          curVideo ? <iframe className='vimeo-video' src={"https://player.vimeo.com/video/" + curVideo} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            : <img src={cover} alt='Siesco Education'/>
        }
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className='card card-lesson'>
        <div className='card-header'>
          <h3 className='card-title'>Videolar</h3>
          {
            auth.userStatus === "Admin" && <button className='card-button' onClick={() => navigate('/editvideos')}>Ayarlar</button>
          }

        </div>
        <ul className='lesson-category'>
          <Treebeard data={data} onToggle={onToggle} decorators={customDecorators} />
        </ul>
      </div>
    </div>
  );
}

export default Videos;
