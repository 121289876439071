import React, { useContext } from 'react';
import '../Login.css';
import logo from '../images/logo white-01 1.png';
import { AuthContext } from '../context/AuthContext'; // AuthContext import edilmesi
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Login() {
    const { login } = useContext(AuthContext); // login fonksiyonunu context'ten alıyoruz
    const navigate = useNavigate();
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await login(e.target.email.value, e.target.password.value);
            navigate('/');
        } catch (error) {
            console.log('Login failed:', error);
        }
    };

    return (
        <div className='root'>
            <div className='login-left'>
                <img src={logo} alt='Logo' />
                <h3>Lorem Ipsum is simply dummy.</h3>
            </div>
            <div className='login-box'>
                <h2>Xoş gəlmisiniz!</h2>
                <form onSubmit={handleLogin}>
                    <div className='txtb'>
                        <label>Email</label>
                        <input type='text' name='email' placeholder='Email' required />
                    </div>
                    <div className='txtb'>
                        <label>Şifrə</label>
                        <input type='password' name='password' placeholder='Şifrə' required />
                    </div>
                    <Link className='reset-pass-btn' to="/resetPassword">Şifrəni unutmuşam</Link>
                    <button type='submit'>Login</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
